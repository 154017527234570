<template>
      <modal :open="modalOpen" @close="$emit('close');" @save="$emit('close');" :modalData="modalData">
        <template v-slot:modalContent>
            <div>
                <v-row align="center" justify="center" class="ma-0">
                    <v-col cols="4">
                        <v-subheader class="text-capitalize">Campaign</v-subheader>
                    </v-col>
                    <v-col cols="5">
                        <v-autocomplete v-model="model.adv_campaign" :loading="searchCampLoading" item-text="name" item-value="sf_id" 
                            hide-details="auto" label="" placeholder="Select Source" :search-input.sync="campaignSearch"
                            :items="searchLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                            outlined hide-no-data return-object @change="model.sf_campaign_id = $event.sf_id"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center" class="ma-0">
                    <v-col cols="4">
                        <v-subheader class="text-capitalize">
                            Donation Type
                            <v-btn class="mx-2 secondary" :disabled="picklistLoading || donateLoading" x-small icon dark @click="getListData('donate')">
                                <v-icon dark size="15" v-if="!donateLoading">mdi-sync</v-icon>
                                <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
                            </v-btn>
                        </v-subheader>
                    </v-col>
                    <v-col cols="5">
                        <div class="skeleton-list-item w-full" v-if="picklistLoading || donateLoading">
                            <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
                        </div>
                        <v-autocomplete v-if="!picklistLoading && !donateLoading" v-model="model.donation_type"
                            hide-details="auto" label="" placeholder="Select Donation Type"
                            :items="Type" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                            outlined
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center" class="ma-0">
                    <v-col cols="4">
                        <v-subheader class="text-capitalize">
                            Source
                            <v-btn class="mx-2 secondary" :disabled="picklistLoading || sourceLoading" x-small icon dark @click="getListData('source')">
                                <v-icon dark size="15" v-if="!sourceLoading">mdi-sync</v-icon>
                                <v-progress-circular v-else size="15" :width="3" color="white" indeterminate></v-progress-circular>
                            </v-btn>
                        </v-subheader>
                    </v-col>
                    <v-col cols="5">
                        <div class="skeleton-list-item w-full" v-if="picklistLoading || sourceLoading">
                            <v-skeleton-loader  :loading="true" type="list-item"></v-skeleton-loader>
                        </div>
                        <v-autocomplete v-if="!picklistLoading && !sourceLoading" v-model="model.source"
                            hide-details="auto" label="" placeholder="Select Source"
                            :items="Donation_Source__c" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
                            outlined
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </div>
        </template>
      </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
    props:["modalOpen"],
    emits:["close"],
    components:{
        Modal: () => import("@/components/common/modal"),
    },
    data() {
        return {
            Type: [],
            Donation_Source__c: [],
            modalData: {
                title: 'advanced options',
                saveBtnTxt: 'Submit',
            },
            searchLists: [],
            searchCampLoading: false,
            campaignSearch: '',
            picklistLoading: false,
            donateLoading: false,
            sourceLoading: false,
            searchTimeId: null
        }
    },
    computed: {
        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("setModel", newValue);
            },
        }   
    },
    watch: {
        campaignSearch(newVal) {
            this.getCampSearchData(newVal);
        }
    },
    created() {
        this.model = {...{sf_campaign_id: null}, ...this.model};
        this.getListData();
        if(this.model.adv_campaign) {
            this.searchLists.push(this.model.adv_campaign);
        }
    },
    methods: {
        ...mapActions(["getSearchPicklist", "getCampaignSearch"]),

        getListData(type) {
            let reqData = [];
            const reqObj = { donate: {object: "Opportunity", field: "Type"},
                source: {object: "Opportunity", field: "Donation_Source__c"}
            };
            if (!type) {
                this.picklistLoading = true;
                reqData = reqObj;
            } else {
                this[`${type}Loading`] = true;
                reqData.push({...reqObj[type], ...{force: 1}});
            }
            this.getSearchPicklist(reqData).then((response) => {
                if (!_.isEmpty(response)) {
                    Object.keys(response).forEach(obj => {
                        this[obj] = response[obj];
                    });
                }
                if (type ) this[`${type}Loading`] = false;
                else this.picklistLoading= false;
            }).catch(err => {
                if (type ) this[`${type}Loading`] = false;
                else this.picklistLoading= false;
            });
        },
        getCampSearchData (val) {
            if (this.searchTimeId) {
                clearTimeout(this.searchTimeId);
                this.searchTimeId = false;
            }
            const self = this;
            this.searchTimeId = setTimeout(async () => {
                self.$store.dispatch('cancelPendingRequests');
                self.searchCampLoading = true;
                const reqData = {
                    name: val || '',
                    columns: 'sf_id, name',
                    limit: 100
                }
                this.getCampaignSearch(reqData).then(resp => {
                    self.searchLists = resp;
                    self.searchCampLoading = false;
                }).catch(err => {
                    self.searchLists = [];
                    if(!err.__CANCEL__) self.searchCampLoading = false;
                });
                self.searchTimeId = false;
            }, 500);
        }
    }
}
</script>
